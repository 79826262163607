import React from 'react';
import { Link } from 'react-router-dom';

export default function HeaderSec() {
  return (
    <>
      <header className="headerSec">
        <div className="container">
          <div className="headerMain">
            <div className="logo">
              <Link to="/">
                <span className="textDark">ARON26</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
