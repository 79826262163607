import React from 'react';
import { Link } from 'react-router-dom';
import HeaderVid from '../img/HeaderVid.webm';

export default function Header() {
  return (
    <>
      <header className="header">
        <video autoPlay muted loop id="myVideo">
          <source src={HeaderVid} type="video/mp4" />
        </video>
        {/* <div className="container">
          <div className="headerMain">
            <div className="logo">
              <Link to="/">
                <span className="textDark">ARON26</span>
              </Link>
            </div>
            <div>
              <Link to="/order" className="btn">
                <span className="textDark">Order</span>
              </Link>
            </div>
          </div>
        </div> */}
      </header>
    </>
  );
}
