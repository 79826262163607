import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '../components/tabs';
import HeaderSec from '../components/headerSec';
import axios from 'axios';

const OrderContext = createContext();

const DefaultCharter = () => {
  const { state, setState } = useContext(OrderContext);
  const { daysExcursion, daysAlcohol } = state;

  const dailyCostExcursion = 1200;
  const dailyCostAlcohol = 100;

  const totalExcursion = daysExcursion * dailyCostExcursion;
  const totalAlcohol = daysAlcohol * dailyCostAlcohol;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalDefaultCharter: totalExcursion + totalAlcohol,
    }));
  }, [daysExcursion, daysAlcohol]);

  return (
    <>
      <div className="orderCard">
        <div className="orderCardItem mb15">
          <span className="textDark fwb">Default Charter</span>
        </div>
        <hr />
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Arranged all-inclusive sightseeing excursion</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Days</span>
            <input
              type="number"
              min={0}
              value={daysExcursion}
              onChange={(e) =>
                setState((prev) => ({ ...prev, daysExcursion: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Daily Cost</span>
            <span className="textDark">€ 1,200.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalExcursion.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Alcohol Supplement</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Days</span>
            <input
              type="number"
              min={0}
              value={daysAlcohol}
              onChange={(e) =>
                setState((prev) => ({ ...prev, daysAlcohol: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Daily Cost</span>
            <span className="textDark">€ 100.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalAlcohol.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const DailyCharters = () => {
  const { state, setState } = useContext(OrderContext);
  const {
    peopleExcursion,
    peopleSupplement,
    peopleBreakfast,
    peopleLunch,
    peopleDinner,
    peopleCocktails,
  } = state;

  const costExcursion = 150;
  const costSupplement = 100;
  const costBreakfast = 25;
  const costLunch = 40;
  const costDinner = 35;
  const costCocktails = 25;

  const totalExcursion = peopleExcursion * costExcursion;
  const totalSupplement = peopleSupplement * costSupplement;
  const totalBreakfast = peopleBreakfast * costBreakfast;
  const totalLunch = peopleLunch * costLunch;
  const totalDinner = peopleDinner * costDinner;
  const totalCocktails = peopleCocktails * costCocktails;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalDailyCharters:
        totalExcursion +
        totalSupplement +
        totalBreakfast +
        totalLunch +
        totalDinner +
        totalCocktails,
    }));
  }, [
    peopleExcursion,
    peopleSupplement,
    peopleBreakfast,
    peopleLunch,
    peopleDinner,
    peopleCocktails,
  ]);

  return (
    <>
      <div className="orderCard">
        <div className="orderCardItem mb15">
          <span className="textDark fwb">Daily Charters</span>
        </div>
        <hr />
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Simple sightseeing excursion per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleExcursion}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleExcursion: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 150.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalExcursion.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Excursion supplement per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleSupplement}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleSupplement: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 100.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalSupplement.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Extra charge for breakfast per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleBreakfast}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleBreakfast: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 25.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalBreakfast.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Extra charge for lunch per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleLunch}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleLunch: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 40.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalLunch.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Dinner supplement per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleDinner}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleDinner: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 35.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalDinner.toFixed(2)}</span>
          </div>
        </div>
        <div className="dfBtw mt15">
          <div className="orderCardItem">
            <span className="textDark">Cocktails surcharge per person</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">People</span>
            <input
              type="number"
              min={0}
              value={peopleCocktails}
              onChange={(e) =>
                setState((prev) => ({ ...prev, peopleCocktails: Number(e.target.value) }))
              }
            />
          </div>
          <div className="orderCardItem">
            <span className="textDark">Cost per person</span>
            <span className="textDark">€ 25.00</span>
          </div>
          <div className="orderCardItem">
            <span className="textDark">Total</span>
            <span className="textDark">€ {totalCocktails.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Order() {
  const [state, setState] = useState({
    daysExcursion: 0,
    daysAlcohol: 0,
    peopleExcursion: 0,
    peopleSupplement: 0,
    peopleBreakfast: 0,
    peopleLunch: 0,
    peopleDinner: 0,
    peopleCocktails: 0,
    totalDefaultCharter: 0,
    totalDailyCharters: 0,
  });

  const total = state.totalDefaultCharter + state.totalDailyCharters;

  const navigate = useNavigate();

  const tabs = [
    {
      label: 'Default Charter',
      content: <DefaultCharter />,
    },
    { label: 'Daily Charters', content: <DailyCharters /> },
  ];

  const sendPayment = async () => {
    const order = {
      'Default Charter': [
        {
          'Arranged all-inclusive sightseeing excursion': {
            days: state.daysExcursion,
          },
        },
        {
          'Alcohol Supplement': {
            days: state.daysAlcohol,
          },
        },
      ],
      'Daily Charters': [
        {
          'Simple sightseeing excursion per person': {
            people: state.peopleExcursion,
          },
        },
        {
          'Excursion supplement per person': {
            people: state.peopleSupplement,
          },
        },
        {
          'Extra charge for breakfast per person': {
            people: state.peopleBreakfast,
          },
        },
        {
          'Extra charge for lunch per person': {
            people: state.peopleLunch,
          },
        },
        {
          'Dinner supplement per person': {
            people: state.peopleDinner,
          },
        },
        {
          'Cocktails surcharge per person': {
            people: state.peopleCocktails,
          },
        },
      ],
    };

    try {
      const response = await axios.post('https://aron26.com/api/payment', { order }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const result = response.data;
      navigate('/payment', {
        state: { clientSecret: result.clientSecret }
      });
    } catch (error) {
      console.error('Payment failed', error);
    }
  };

  return (
    <OrderContext.Provider value={{ state, setState }}>
      <HeaderSec />
      <section className="order">
        <div className="orderMain">
          <Tabs tabs={tabs} />
          <div className="orderCardItem mt15 dfBtw">
            <div className="df">
              <button className="btn" onClick={sendPayment}>
                Proceed
              </button>
            </div>
            <div>
              <span className="textDark fwb">Total</span>
              <span className="textDark fwb">€ {total.toFixed(2)}</span>
            </div>
          </div>
        </div>
        {/* <div className="payment">
          <div className="orderCardItem mb15">
            <span className="textDark fwb">Payment:</span>
          </div>
        </div> */}
      </section>
    </OrderContext.Provider>
  );
}
