
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useState} from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';

import HeaderSec from '../components/headerSec';

const stripePromise = loadStripe('pk_live_51PYUgvRppszCvqhs1HlzwiLfn6m6VbVgavqx8G4yd9mfMT60Ga1mcTruOeIxfM2WiBbpTOeWg4Hn63MbbtwIbiWK0080REmSLT');
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
const PaymentForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements) {
      return;
    }

    // Show modal after 1 second
    setTimeout(() => {
      setShowModal(true);
      // Redirect to '/' after another 2 seconds
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }, 1000);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      if (clientSecret) {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: 'Customer Name',
            },
          },
        });

        if (result.error) {
          console.log('[error]', result.error);
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            console.log('Payment succeeded!');
          }
        }
      }
    }
  };

  return (
    <div className='submitForm'>
        <form onSubmit={handleSubmit}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <button type="submit" className="btn" disabled={!stripe}>
            Pay
        </button>
        </form>
        {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Payment sent for processing</h2>
           
          </div>
        </div>
        )}
        <style jsx>{`
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 5px;
          }
          .modal-content h2 {
            color: #00a31c;
          }
        `}</style>
    </div>
    
  );
};

const PaymentPage = () => {
  const location = useLocation();
  const clientSecret = location.state?.clientSecret;

  console.log('PaymentPage rendered');
  console.log('clientSecret:', clientSecret);

  if (!clientSecret) {
    return <div>Error: No client secret provided</div>;
  }

  return (
    <>
        <HeaderSec/>
        <Elements stripe={stripePromise}>
        <PaymentForm clientSecret={clientSecret} />
        </Elements>
    </>
    
  );
};

export default PaymentPage;
