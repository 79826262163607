import React from 'react';
import './css/style.css';
import Footer from './components/footer';
import Main from './pages/main';
import Order from './pages/order';
import PaymentPage from './pages/paymentPage';
import Policy from './pages/policy';
import Terms from './pages/terms';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" index element={<Main />} />
        <Route path="order" element={<Order />} />
        <Route path="payment" element={<PaymentPage/>} />
        <Route path="privacyPolicy" element={<Policy/>} />
        <Route path="termsOfUse" element={<Terms/>} />

      </Routes>
      <Footer />
    </div>
  );
}


export default App;

