import React from 'react';
import YachtImg from '../img/yacht.jpg';
import { Link } from 'react-router-dom';
import Header from '../components/header';

export default function Main() {
  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="saleMain">
            <div className="land">
              <img className="landImg" src={YachtImg} />
              <div className="textLg textDark">Order the unforgettable trip right now!</div>
            </div>
            <div className="btnHolder">
              <Link to="/order" className="btn">
                <span className="textDark">Order</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
