import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footerMain">
            <div className="logo">
              <Link to="/">
                <span className="textDark">ARON26</span>
              </Link>
            </div>
            <div className='df'>
              <span className="textDark">+30 693 330 32 73</span>
              <span className="textDark">office@aron26.com</span>
            </div>
            <div className='df'>
              <Link to="/privacyPolicy">
                <span className="textDark">Privacy Policy</span>
              </Link>
              <Link to="/termsOfUse">
                <span className="textDark">Terms of Use</span>
              </Link>
            </div>
            
          </div>
        </div>
      </footer>
    </>
  );
}
