import React from 'react';
import HeaderSec from '../components/headerSec';

export default function Terms() {
    return(
        <>
            <HeaderSec/>
            <div className='policyHolder'>
                <h2>Terms and Conditions</h2>
                <br/><br/>
                These are the terms and conditions for the use of our website aron26.sumupstore.com for the purchase of services through the website.
                <br/><br/>
                If you have any questions about these terms, please contact us via the contact form.

                The terms and conditions contained herein and any notices or conditions on other areas of this website shall jointly govern customers' use of this website. Please note that the organizing company may at any time make changes or remove part of this website without any liability to customers for such changes. The Organizing Company reserves the right to amend these terms and conditions without informing the customers and the use of the website or the sending of orders after the introduction of such changes will be interpreted as acknowledgment and acceptance of the same.

                When a customer places an order through this website, they warrant, by placing the order:-

                1.      You are not a minor or otherwise legally incapable of entering into a binding contract.

                2.      That the personal data provided at the time of registration is complete and accurate.

                3.      You will not use a false name or the name of any other person or entity that you are not authorized to use.

                

                <br/><br/>
                <h2>Placing an order</h2>
                <br/><br/>
                

                When placing an order, you understand that by doing so you are agreeing to these terms and conditions, so it is important that you have read them before proceeding with your order.

                <br/><br/>


                <h2>Cancelling an order</h2>
                <br/><br/>
                

                You can cancel an order within 14 days and the amount will be refunded to the payment method with which the order was originally paid.

                <br/><br/>


                <h2>Accepting an order</h2>

                <br/><br/>

                When an order is placed, the customer receives an order confirmation with information regarding the content of the order and/or the type of service requested at the e-mail address you indicated at the time of payment.
                <br/><br/>
                
                <h2>Execution</h2>

                <br/><br/>

                The contract that regulates the execution of the service between the customer and our company will be sent in advance to the customer's email which will be returned signed later, but before boarding. The customer has 14 days to cancel the requested service, after which the service is considered accepted. See the sections "Cancelling an order" and "Withdrawal policy".

                <br/><br/>
                <h2>Withdrawal Policy</h2>

                <br/><br/>
                The organizing company accepts the return within 14 days, after the term of 14 days, nothing will be due to the Customer.

                The withdrawal must be in writing and sent to the following interlocutors:

                By email to office@aron26.com

                By text message to +306933303273

                

                If the withdrawal takes place within the established terms, the company organizing the purchased service will refund the entire amount in the same way as it was paid.

                <br/><br/>
                <h2>Responsibility</h2>

                <br/><br/>

                We endeavour to ensure that the information on this website is as accurate as possible, but we make no warranties, express or implied, as to its accuracy. In addition, we do not provide any warranties regarding matters relating to the use of this website, so it is your responsibility to ensure that your equipment is protected from viruses or other external factors.

                <br/><br/>

                Your rights are protected by the Sale of Goods and Supply of Services Act 1980, as well as the Consumer Protection Act 2007, as a consumer. Nothing on this website affects your rights under applicable law.

                <br/><br/>


                <h2>Partial nullity</h2>

                <br/><br/>

                If any of these terms and conditions are found to be void, illegal or unenforceable for any reason, such terms and conditions shall be deemed severable from the remaining terms and conditions which shall remain valid and enforceable.

                <br/><br/>

                If any provision of these terms and conditions is unlawful, void, or for any reason unenforceable, that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.

                
            </div>
        </>
    )
}