import React, { useState } from 'react';

const Tab = ({ activeTab, index, label, onClick }) => {
  const classes = ['tab-list-item'];

  if (activeTab === index) {
    classes.push('tabActive');
  }

  return (
    <li className={classes.join(' ')} onClick={onClick}>
      {label}
    </li>
  );
};

export default function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="tabs">
      <ul className="tab-list">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            index={index}
            label={tab.label}
            activeTab={activeTab}
            onClick={() => setActiveTab(index)}
          />
        ))}
      </ul>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
}
